import AgoraRTC, { type EncryptionMode } from 'agora-rtc-sdk-ng';
import { Oasis } from '../..';
import { Err, Ok } from '../../lib/result';

export const Agora = {
  client: AgoraRTC.createClient({ mode: 'live', codec: 'vp8', role: 'host' }),

  createMicrophoneAudioTrack: AgoraRTC.createMicrophoneAudioTrack,

  async connect(params: {
    channel: string;
    channelToken: string;
    uid: number;
    encryptionMode: string;
    encryptionKey: string;
    encryptionSalt64BitEncoded: string;
  }) {
    try {
      const appId = '6b9b3304fe9f41dc97e028c512286f8e';

      Oasis.Logger.debug({ msg: '[Agora.connect] Connecting to Agora', params });

      // https://docs.agora.io/en/voice-calling/core-functionality/media-stream-encryption?platform=web
      const mode = params.encryptionMode.replaceAll('_', '-').toLowerCase() as EncryptionMode;
      const secret = _decodeKey(params.encryptionKey);
      const salt = _decodeSalt(params.encryptionSalt64BitEncoded);

      Oasis.Logger.debug({ msg: '[Agora.connect] Setting encryption config', mode, secret, salt });
      Agora.client.setEncryptionConfig(mode, secret, salt);
      const id = await Agora.client.join(appId, params.channel, params.channelToken, params.uid);

      return Ok(id);
    } catch (error) {
      Oasis.Logger.error({ error, msg: '[Agora.connect] Error connecting to Agora' });
      return Err({ code: 'NOT_CONNECTED' });
    }
  },

  disconnect() {
    return Agora.client.leave();
  },
};

function _decodeSalt(base64Str: string): Uint8Array {
  const raw = window.atob(base64Str);
  const result = new Uint8Array(new ArrayBuffer(raw.length));

  for (let i = 0; i < raw.length; i += 1) {
    result[i] = raw.charCodeAt(i);
  }

  return result;
}

function _decodeKey(hex: string) {
  if (hex.length % 2 !== 0) {
    throw new Error('Invalid hex string');
  }

  let binaryString = '';
  for (let i = 0; i < hex.length; i += 2) {
    const byte = parseInt(hex.substring(i, i + 2), 16); // Convert hex pair to byte
    binaryString += String.fromCharCode(byte); // Convert byte to character
  }

  return binaryString;
}

import ProgressRing from '@adsk/alloy-react-progress-ring';
import { Oasis } from '@oasis/sdk';
import clsx from 'clsx';
import { throttle } from 'lodash';
import { useEffect, useRef, useState } from 'react';
import { useProxy } from 'valtio/utils';
import { MicIcon } from '~/features/workshops/pages/viewer/components/waiting-room-voice/icons/mic';
import { MicOffIcon } from '~/features/workshops/pages/viewer/components/waiting-room-voice/icons/mic-off';
import { NotificationManager } from '~/shared/components/base/notification-manager';
import {
  CollaborationExtensionState,
  useCollaborationExtension,
  type ExtensionLoadingState,
} from '~/shared/hooks/use-collaboration-extension';
import { useFeatureFlags } from '~/shared/hooks/use-feature-flags';
import { addCustomControl } from '../viewer-toolbar';
import { FluidErrorState } from './components/fluid-error-state';

interface Props {
  versionId: string;
  isCollaborativeWebViewer?: boolean;
  workshopId?: string;
  onCollabExtStateChange?: (state: ExtensionLoadingState) => void;
}

export function LargeModelViewer(props: Props) {
  const $env = Oasis.Env.useStore();
  const $voice = Oasis.Voice.useStore();
  const $debug = Oasis.Debug.useStore();
  const $collab = useProxy(CollaborationExtensionState);
  const [isCameraSync] = useFeatureFlags(['241015-7221-unreal-lmv-cameras-sync']);
  const noGeometryLoading = $env.isVr && !isCameraSync;
  const throttleLMV = $env.isVr && !isCameraSync;

  const isCollaborativeWebViewer = props.isCollaborativeWebViewer || $debug.isWebViewer;
  const ref = useRef<HTMLDivElement>(null);
  const viewer = useRef<Autodesk.Viewing.GuiViewer3D>();
  const [viewerCreated, setViewerCreated] = useState(false);
  const listenerAdded = useRef(false);
  const resourcesLoaded = _useLoadResources({
    throttleLMV: !!throttleLMV,
    noGeometryLoading: !!noGeometryLoading,
    isCollaborativeWebViewer: isCollaborativeWebViewer,
  });
  const [modelLoaded, updateModelLoaded] = useState(false);

  const { connectionStatus, shouldLoadExtension } = useCollaborationExtension({
    viewer: viewer.current,
    versionId: props.versionId,
    modelLoaded,
    isCollaborativeWebViewer: isCollaborativeWebViewer,
    onStateChange: props.onCollabExtStateChange,
  });

  useEffect(
    () => {
      if (!resourcesLoaded || !ref.current || modelLoaded) {
        return;
      }

      if (!viewer.current) {
        viewer.current = new Autodesk.Viewing.GuiViewer3D(ref.current);
        setViewerCreated(true);
      }

      async function onInitialized() {
        if (!viewer.current) {
          Oasis.Logger.error({ msg: '<LargeModelViewer /> LMV failed to initialize.' });
          return;
        }

        viewer.current.start();
        viewer.current.setQualityLevel(false, false);

        viewer.current.loadExtension('Autodesk.ModelStructure', {}).then(ext => {
          if (!viewer.current) {
            return;
          }
          const newViewerModelStructurePanel = new Autodesk.Viewing.Extensions.ViewerModelStructurePanel(
            viewer.current,
            // @ts-ignore
            // @TODO Viewer provide inconsistent types for the model structure panel.
            'ModelBrowser',
            {
              // This will prevent hiding search bar on console.
              hideSearch: false,
              // This will disable isolation when clicking on a node.
              // These caused inconsistency between the the model structure for current user and collaborators.
              // For now we toggle visibility when clicking on a node.
              docStructureConfig: { click: { onObject: ['selectOnly'] } },
            }
          );
          const activate = () => {
            viewer.current?.setModelStructurePanel(newViewerModelStructurePanel);
            if (!isCollaborativeWebViewer) {
              newViewerModelStructurePanel.setVisible(true);

              ext.activate('');
            }
          };
          // if geometry is already loaded, activate the panel
          if (viewer.current.model) {
            console.log('Geometry already loaded: activate model structure panel');
            activate();
          } else {
            viewer.current.addEventListener(Autodesk.Viewing.OBJECT_TREE_CREATED_EVENT, () => {
              console.log('OBJECT_TREE_CREATED_EVENT: activate model structure panel');
              activate();
            });
          }
        });

        viewer.current.setTheme(isCollaborativeWebViewer ? 'dark-theme' : 'light-theme');

        function waitForPropertyDb() {
          if (!viewer.current) {
            throw new Error('Viewer object undefined');
          }
          if ((viewer.current.model.getPropertyDb() as any).isLoadDone()) {
            Oasis.Logger.info('Property DB Load completed.');
            updateModelLoaded(true);
            if (listenerAdded.current) {
              viewer.current.removeEventListener(Autodesk.Viewing.OBJECT_TREE_CREATED_EVENT, waitForPropertyDb);
              listenerAdded.current = false;
            }
          } else {
            if (!listenerAdded.current) {
              Oasis.Logger.info('Waiting for property DB to load.');
              viewer.current.addEventListener(Autodesk.Viewing.OBJECT_TREE_CREATED_EVENT, waitForPropertyDb);
              listenerAdded.current = true;
            }
          }
        }

        let urn = `urn:${btoa(props.versionId)}`;
        urn = urn.replace('/', '_');

        Autodesk.Viewing.Document.load(
          urn,
          async doc => {
            if (!viewer.current) {
              Oasis.Logger.error({ doc, urn, msg: '<LargeModelViewer /> LMV failed to initialize.' });
              return null;
            }

            const originalTick = viewer.current.impl.tick;
            if (throttleLMV) {
              // @ts-ignore
              viewer.current.impl.setFPSTargets(0, 1000, 1000);
              // Render rarely until we have loaded the geometry
              viewer.current.impl.tick = throttle(originalTick, 1000);
            }
            const root = doc.getRoot();
            const model = root.getDefaultGeometry();

            if (noGeometryLoading) {
              viewer.current.addEventListener(Autodesk.Viewing.TOOLBAR_CREATED_EVENT, e => {
                const toolbar = e.target.toolbar;
                // Hide the toolbar entirely
                toolbar.setVisible(false);
              });

              // @ts-ignore
              viewer.current.addEventListener(Autodesk.Viewing.VIEW_CUBE_CREATED_EVENT, () => {
                // @ts-ignore
                const viewCubeWrapper = document.getElementsByClassName('viewcubeWrapper');
                if (viewCubeWrapper.length > 0 && viewCubeWrapper[0] && (viewCubeWrapper[0] as HTMLElement).style) {
                  (viewCubeWrapper[0] as HTMLElement).style.display = 'none';
                }
              });
            }

            // reduce throttling to render more often after geometry has loaded
            viewer.current.addEventListener(Autodesk.Viewing.GEOMETRY_LOADED_EVENT, () => {
              if (throttleLMV) {
                // @ts-ignore
                viewer.current.impl.setFPSTargets(0, 10, 10);
                // @ts-ignore
                viewer.current.impl.tick = throttle(originalTick, 100);
              }
            });

            try {
              await viewer.current.loadDocumentNode(doc, model, { skipMeshLoad: noGeometryLoading });
              waitForPropertyDb();
              Oasis.Logger.info({ msg: 'Successfully loaded' });
            } catch (error) {
              Oasis.Logger.error({
                msg: '<LargeModelViewer /> Failed to load document.',
                error,
              });
            }
          },
          errorCode => {
            Oasis.Logger.error({ msg: '<LargeModelViewer /> Error loading document: ', errorCode });
          },
          null
        );
      }
      const resourceBase = Oasis.ApsHttp.url('modelderivative/v2', 'viewers/');

      Autodesk.Viewing.Initializer(
        {
          env: Oasis.Env.store.lmvEnv, // 'AutodeskStating2' || 'AutodeskProduction2',
          api: 'streamingV2',
          language: 'en',
          getAccessToken: async cb => {
            const result = await Oasis.TokenManager.getAccessToken();

            if (result.ok) {
              cb?.(result.value, Oasis.TokenManager.getExpiration());
            } else {
              NotificationManager.push({
                status: 'error',
                content: 'Failed to verify session.',
              });
            }
          },
          refreshToken: async cb => {
            const result = await Oasis.TokenManager.refresh();

            if (result.ok) {
              cb?.(result.value.oxygenToken, Oasis.TokenManager.getExpiration());
            } else {
              NotificationManager.push({
                status: 'error',
                content: 'Failed to refresh session.',
              });
            }
          },
          optOutTrackingByDefault: true,
          useADP: false,
          lmvResourceRoot: resourceBase,
        },
        onInitialized
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [resourcesLoaded, modelLoaded, props.versionId, isCollaborativeWebViewer]
  );

  useEffect(() => {
    if (!viewerCreated || !viewer.current || !isCollaborativeWebViewer) return;

    const customToolbarCallback = () => {
      if (viewer.current) {
        addCustomControl(viewer.current, 'voice-button', 'voice', <MuteButtonIcon />, Oasis.Voice.toggleMuted);
      }
    };

    viewer.current.addEventListener(Autodesk.Viewing.TOOLBAR_CREATED_EVENT, () => {
      customToolbarCallback();
    });

    if (viewer.current.toolbar) {
      customToolbarCallback();
    }
  }, [viewerCreated, isCollaborativeWebViewer]);

  useEffect(() => {
    return () => {
      if (viewer.current) {
        Oasis.Logger.info('<LargeModelViewer /> Shutting down viewer.');
        viewer.current.finish();
        Autodesk.Viewing.shutdown();
        viewer.current = undefined;
      }
    };
  }, []);

  return (
    <div
      ref={ref}
      className={`${clsx('relative flex flex-1 overflow-hidden -z-0', $collab.status === 'LOADING' || (connectionStatus === 'CONNECTING' && 'is-loading'))}`}
    >
      {shouldLoadExtension || $env.isVr ? (
        $collab.status !== 'FAILED' && connectionStatus !== 'ERROR' ? (
          <div
            className={clsx(
              'flex-1 items-center justify-center flex-col flex',
              $collab.status !== 'LOADED' || connectionStatus !== 'CONNECTED' ? 'visible' : 'hidden'
            )}
          >
            <>
              <ProgressRing size="large" />
              <p className="text-body-lg mt-5">Syncing. This may take few seconds...</p>
              <div />
            </>
          </div>
        ) : (
          <div className="absolute bg-white inset-0 z-10 flex items-center justify-center">
            <FluidErrorState />
          </div>
        )
      ) : null}
    </div>
  );
}

function _useLoadResources(params: {
  throttleLMV: boolean;
  noGeometryLoading: boolean;
  isCollaborativeWebViewer?: boolean;
}) {
  const [styleLoaded, setStyleLoaded] = useState(Boolean(window.Autodesk));
  const [scriptLoaded, setScriptLoaded] = useState(Boolean(window.Autodesk));
  const $env = Oasis.Env.useStore();

  useEffect(() => {
    if (scriptLoaded) {
      // We enable LargeModelExperience for camera sync to improve performance.
      // @ts-ignore
      const flag = Autodesk?.Viewing?.PublicFeatureFlags?.LargeModelExperience;
      // @ts-ignore
      const flagValue = window.Autodesk?.Viewing?.FeatureFlags?.[flag];
      if (params.isCollaborativeWebViewer && flagValue !== true) {
        // @ts-ignore
        window.Autodesk?.Viewing?.FeatureFlags?.set(flag, true);
      }
    }
  }, [scriptLoaded, params.isCollaborativeWebViewer]);

  useEffect(
    () => {
      if (!window.Autodesk) {
        const resourceBase = Oasis.ApsHttp.url('modelderivative/v2', 'viewers/');
        const style = document.createElement('link');
        if ($env.isVrWorkshop || $env.isVrHomespace) {
          if (params.noGeometryLoading) {
            style.href = '/css/viewerstyle.min.NOGEOMETRY.css';
          } else {
            if (!params.isCollaborativeWebViewer) {
              style.href = '/css/viewerstyle.min.webviewer.css';
            }
          }
        } else {
          style.href = `${resourceBase}/style.min.css`;
        }
        style.rel = 'stylesheet';
        style.type = 'text/css';
        style.onload = () => setStyleLoaded(true);

        const script = document.createElement('script');
        script.src = `${resourceBase}/viewer3D.min.js`;
        script.async = true;
        script.onload = () => setScriptLoaded(true);

        document.body.appendChild(style);
        document.body.appendChild(script);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return styleLoaded && scriptLoaded;
}

function MuteButtonIcon() {
  const $voice = Oasis.Voice.useStore();
  return $voice.muted ? <MicOffIcon className="w-6 m-auto text-red-500" /> : <MicIcon className="w-6 m-auto" />;
}

import Accordion, { AccordionItem } from '@adsk/alloy-react-accordion';
import { XIcon } from '@adsk/alloy-react-icon';
import { RadioButton, RadioGroup } from '@adsk/alloy-react-radio-button';
import Slider from '@adsk/alloy-react-slider';
import { OverflowTooltip } from '@adsk/alloy-react-tooltip';
import { Oasis } from '@oasis/sdk';
import { useEffect, useState } from 'react';
import { Volume1Icon } from './waiting-room-voice/icons/volume-1';
import { Volume2Icon } from './waiting-room-voice/icons/volume-2';
import { VolumeXIcon } from './waiting-room-voice/icons/volume-x';

interface Props {
  onClose?: () => void;
}

export function ViewerSettingsPanel(props: Props) {
  const $voice = Oasis.Voice.useStore();
  const [inputs, setInputs] = useState<MediaDeviceInfo[]>([]);
  const [outputs, setOutputs] = useState<MediaDeviceInfo[]>([]);

  useEffect(() => {
    Oasis.Voice.getDevices()
      .then(devices => {
        setInputs(devices.inputs);
        setOutputs(devices.outputs);
      })
      .catch(err => {
        console.error(`${err.name}: ${err.message}`);
      });
  }, []);

  const deviceGroups = [
    {
      title: 'Speaker',
      devices: outputs,
      activeDevice: $voice.activeOutputId,
      onChange: (id?: string) => {
        if (id) {
          Oasis.Voice.store.activeOutputId = id;
          Oasis.Voice.mic?.setPlaybackDevice(id);
        }
      },
    },
    {
      title: 'Microphone',
      devices: inputs,
      activeDevice: $voice.activeInputId,
      onChange: (id?: string) => {
        if (id) {
          Oasis.Voice.store.activeInputId = id;
          Oasis.Voice.mic?.setDevice(id);
        }
      },
    },
  ] as const;

  return (
    <aside className="absolute top-0 left-0 bottom-0 bg-white shadow-high w-80 overflow-auto">
      <header className="flex items-center justify-between p-4 border-b border-charcoal-100">
        <h3 className="text-heading-1">Settings</h3>
        <button onClick={props.onClose}>
          <XIcon />
        </button>
      </header>
      <Accordion multiExpand initialExpanded={[0, 1]}>
        {deviceGroups.map(({ title, devices, activeDevice, onChange }) => (
          <AccordionItem key={title} title={title}>
            {title === 'Speaker' && (
              <div className="flex items-center pt-4 px-4">
                {$voice.volume === 0 ? (
                  <VolumeXIcon className="mr-4 w-6 h-6 text-charcoal-900" />
                ) : $voice.volume < 65 ? (
                  <Volume1Icon className="mr-4 w-6 h-6 text-charcoal-900" />
                ) : (
                  <Volume2Icon className="mr-4 w-6 h-6 text-charcoal-900" />
                )}

                <Slider initialValue={[100]} onChange={value => Oasis.Voice.setVolume(value[0] ?? 100)} />
              </div>
            )}

            <div className="py-4">
              <RadioGroup value={activeDevice} onChange={onChange}>
                <ul className="space-y-4 px-4">
                  {devices.map(device => {
                    let label = device.label;

                    if (device.deviceId === 'default') {
                      label = `System Default - ${label?.replace('Default - ', '')}`;
                    }

                    if (!label) {
                      // I'm not sure why I'm getting devices without labels, but it's happening.
                      return null;
                    }

                    const htmlId = `${title}-${device.deviceId}`;

                    return (
                      <li key={device.deviceId}>
                        <label htmlFor={htmlId} className="flex items-center">
                          <RadioButton id={htmlId} className="mr-2" value={device.deviceId} />
                          <div className="flex-1 overflow-hidden">
                            <OverflowTooltip content={label}>{label}</OverflowTooltip>
                          </div>
                        </label>
                      </li>
                    );
                  })}
                </ul>
              </RadioGroup>
            </div>
          </AccordionItem>
        ))}
      </Accordion>
    </aside>
  );
}

import { ConnectionManager } from '@oasis/fluid-interop';
import { proxy } from 'valtio';
import { useProxy } from 'valtio/utils';
import { Err, Ok } from '../../lib/result';
import { Oasis } from '../../oasis';

export interface FluidConnection {
  userData: {
    lastName: string;
    id: string;
    firstName: string;
  };
}

export const Fluid = {
  store: proxy<FluidConnection>({
    userData: {
      id: '',
      firstName: '',
      lastName: '',
    },
  }),

  useStore: () => useProxy(Fluid.store),

  connections: new Map<'workshop', ConnectionManager>(),

  async init() {
    if (Oasis.Session.store.status !== 'AUTHENTICATED') {
      Oasis.Logger.error({ msg: 'Fluid require the user to be authenticated.' });
      return Err({ code: 'UNAUTHORIZED' });
    }

    const { id, firstName, lastName } = Oasis.Session.store.user;

    Fluid.store.userData.id = id;
    Fluid.store.userData.firstName = firstName;
    Fluid.store.userData.lastName = lastName;

    return Ok(true);
  },

  async getConnectionManager() {
    const { id, firstName, lastName } = Fluid.store.userData;
    const images = Oasis.Session.store.user?.images;
    const env = Oasis.Env.store.fluidEnv;
    const inVr = Oasis.Env.store.isVr;
    // @TODO - Make sure we don't provide device
    // if we are not interested unreal to send
    // users camera sync. e.g
    // inVr ? 'VR' : isCollaborativeWebViewer: 'WEB' : 'WEB_NO_SYNC'
    const device = inVr ? 'WEB-VR' : 'WEB';
    const newId = `${id}-${device}`;
    // hash newId to generate deviceId
    const connectionManager = new ConnectionManager(
      async () => {
        const token = await Oasis.TokenManager.getAccessToken();
        if (!token.ok) {
          throw new Error('Invalid token');
        }
        return token.value;
      },
      () => ({
        // This should always be the user's oxygenId, Fluid backend relies on this to ensure 
        // the user's identity and it should always match the oxygenId in the user's token.
        id,
        userId: newId,
        additionalDetails: {
          userImage: images?.sizeX120,
          device,
          userLastName: lastName,
          userName: firstName,
          userId: id,
        },
      }),
      env as 'production' | 'staging' | undefined,
      {
        send: event => {
          // Telemetry can be verbose we can decide to what level we want to log
          // For now we only log errors
          if (event.category === 'error') {
            Oasis.Logger.error({ msg: `[Fluid] Error eventName: ${event.eventName}`, event });
          }
        },
      }
    );

    return connectionManager;
  },

  async connectToDocument(documentId: string) {
    try {
      const workshopConnection = await this.getConnectionManager();

      if (workshopConnection.isConnected) {
        return Ok(workshopConnection);
      }

      await workshopConnection.connect(documentId);
      Oasis.Logger.debug(
        `[Fluid.connectToWorkshopDocument] Connected successfully to the document ${documentId} on ${Oasis.Env.store.fluidEnv}`
      );
      return Ok(workshopConnection);
    } catch (err: any) {
      return Err(err);
    }
  },

  async createWorkshopDocument(workshopId: string) {
    try {
      const workshopConnection = await this.getConnectionManager();
      const documentId = await workshopConnection.create({
        resourceUrn: workshopId,
        namespace: Oasis.Env.store.acmNamespace,
      });
      Oasis.Logger.debug(
        `[Fluid.createWorkshopDocument] connected successfully to the workshop ${workshopId} with document ${documentId} on ${Oasis.Env.store.fluidEnv}`
      );
      return Ok(workshopConnection);
    } catch (err: any) {
      return Err(err);
    }
  },

  /*
   * Helper method to connect to fluid document, it handles special case when a workshop has no documentId, in
   * that case it creates a new documentId and updates the workshop data model with the new documentId.
   * This is short-term solution as currently the fluid document creation is handled by the client.
   */
  connectToWorkshop: async (activeWorkshopId: string, workshopDocumentId?: string) => {
    let res: { ok: true; value: ConnectionManager } | { ok: false; error: any } | undefined;

    if (!workshopDocumentId) {
      Oasis.Logger.debug(`Backend fluid documentId is not found for workshop ${activeWorkshopId}, creating new one!`);
      Oasis.Logger.info(`Creating new fluid documentId for workshop ${activeWorkshopId}`);

      res = await Oasis.Fluid.createWorkshopDocument(activeWorkshopId);

      if (res.ok) {
        const documentId = res.value?.documentId;
        const updateRes = await Oasis.Workshops.updateWorkshop({
          workshopId: activeWorkshopId,
          attrs: {
            fluidState: {
              id: documentId,
            },
          },
        });
        if (!updateRes.ok) {
          const errMessage = `Failed to update fluid documentId for workshop ${activeWorkshopId}, ${updateRes.error}`;
          Oasis.Logger.error(errMessage);
          throw new Error(errMessage);
        }
      }
    }

    if (workshopDocumentId) {
      res = await Oasis.Fluid.connectToDocument(workshopDocumentId);
    }

    if (res?.ok) {
      const connectionManager = res.value;
      return connectionManager;
    } else {
      const errMessage = `Failed to connect to fluid document for workshop ${activeWorkshopId}, ${res?.error}`;
      Oasis.Logger.error({
        msg: errMessage,
        error: res?.error,
      });
      throw new Error(errMessage);
    }
  },
};


import { ArrayUtils } from '@oasis/utils';
import { HTTPError, Options } from 'ky';
import { useProxy } from 'valtio/utils';
import { proxy } from 'valtio/vanilla';
import { Err, Ok } from '../../lib/result';
import { HttpUtils } from '../../lib/utils.http';
import { Oasis } from '../../oasis';
import { ApsHttp } from '../../providers/http/aps-http.provider';
import { OasisHttp } from '../../providers/http/oasis-http.provider';
import { Segment } from '../../providers/segment/segment.provider';
import { WorkshopPermissions } from '../../types';
import { Env } from '../env/env.service';
import { Session } from '../session/session.service';
import { WorkshopsSchemas } from './workshops.schemas';

type WorkshopState = {
  activeWorkshopId: string;
};

export const Workshops = {
  store: proxy<WorkshopState>({
    activeWorkshopId: '',
  }),

  useStore: () => useProxy(Workshops.store),

  /**
   * @name createWorkshop
   * Create a workshop for the current logged in user.
   */
  async createWorkshop(params: {
    projectId: string;
    attrs: {
      name: string;
      environment: string;
      fluidState?: {
        id?: string;
      };
    };
  }) {
    try {
      const data = WorkshopsSchemas.createWorkshop.parse(
        await OasisHttp.post('v1/workshops', {
          body: JSON.stringify({
            projectId: params.projectId,
            name: params.attrs.name,
            environment: params.attrs.environment,
            fluidState: params.attrs.fluidState,
            settings: {
              version: '1',
              logo: 'some-logo.jpg',
              seating: {
                url: 'url',
                count: 1,
              },
            },
          }),
        }).json()
      );

      Segment.track('Workshop Created', params);

      return Ok(data);
    } catch (error) {
      return HttpUtils.handleError(error, '[Workshops.createWorkshop]');
    }
  },

  /**
   * @name list
   * Get a list of workshops for a user.
   */
  async listWorkshops(params: { projectId: string; opts?: Options }) {
    try {
      const searchParams = new URLSearchParams();
      searchParams.set('filter[project]', params.projectId);

      const data = WorkshopsSchemas.listWorkshops.parse(
        await OasisHttp.get('v1/workshops/', { ...params?.opts, searchParams }).json()
      );

      return Ok({
        pagination: data.pagination,
        results: data.results
          ? data.results.sort((a, b) => ArrayUtils.sortDateString(a.createdDate, b.createdDate))
          : [],
      });
    } catch (error) {
      return HttpUtils.handleError(error, '[Workshops.listWorkshops]');
    }
  },

  /**
   * @name findWorkshopById
   * Find a workshop by it's id.
   */
  async findWorkshopById(workshopId: string) {
    try {
      const data = WorkshopsSchemas.findWorkshopById.parse(await OasisHttp.get(`v1/workshops/${workshopId}`).json());
      return Ok(data);
    } catch (error) {
      return HttpUtils.handleError(error, '[Workshops.findWorkshopById]');
    }
  },

  /**
   * @name updateWorkshop
   * Update a workshop.
   */
  async updateWorkshop(params: {
    workshopId: string;
    attrs: {
      name?: string;
      fluidState?: {
        id?: string;
      };
    };
  }) {
    try {
      const data = WorkshopsSchemas.updateWorkshop.parse(
        await OasisHttp.patch(`v1/workshops/${params.workshopId}`, {
          body: JSON.stringify(params.attrs),
        }).json()
      );

      Segment.track('Workshop Updated', params);

      return Ok(data);
    } catch (error) {
      return HttpUtils.handleError(error, '[Workshops.findWorkshopById]');
    }
  },

  /**
   * @name deleteWorkshop
   * Delete a workshop.
   */
  async deleteWorkshop(workshopId: string) {
    try {
      await OasisHttp.delete(`v1/workshops/${workshopId}`).json();
      Segment.track('Workshop Deleted', { workshopId });
      return Ok(true);
    } catch (error) {
      return HttpUtils.handleError(error, '[Workshops.deleteWorkshop]');
    }
  },

  /**
   * @name listACCUsers
   * Get a list of ACC users.
   */
  async listACCUsers(projectId: string) {
    try {
      const searchParams = new URLSearchParams();
      searchParams.set('limit', '100');

      const data = WorkshopsSchemas.listACCUsers.parse(
        await ApsHttp.client
          .get(`construction/admin/v1/projects/${projectId}/users`, {
            searchParams,
          })
          .json()
      );

      return Ok(data);
    } catch (error) {
      return HttpUtils.handleError(error, '[Workshops.listACCUsers]');
    }
  },

  /**
   * @name listWorkshopMembers
   * Get a list of Oasis Workshop members.
   */
  async listWorkshopMembers(workshopId: string, opts?: Options) {
    try {
      const data = WorkshopsSchemas.listWorkshopMembers.parse(
        await OasisHttp.get(`v1/workshops/${workshopId}/permissions`, opts).json()
      );

      return Ok({
        ...data,
        results: {
          admins: [
            ...data.results.admins.map(admin => ({
              ...admin,
              permission: 'MANAGE' as const,
            })),
          ],
          members: [...data.results.members],
        },
      });
    } catch (error) {
      return HttpUtils.handleError(error, '[Workshops.listWorkshopMembers]');
    }
  },

  /**
   * @name listActiveUsers
   * Get a list of users currently in a workshop.
   */
  async listActiveUsers(workshopId: string, opts?: Options) {
    try {
      const data = WorkshopsSchemas.listActiveUsers.parse(
        await OasisHttp.get(`v1/workshops/${workshopId}/users`, opts).json()
      );

      return Ok(data);
    } catch (error) {
      return HttpUtils.handleError(error, '[Workshops.listActiveUsers]');
    }
  },

  /**
   * @name updateWorkshopPermissions
   * Update user permissions, either granting or revoking.
   */
  async updateWorkshopPermissions(params: {
    workshopId: string;
    data: {
      userIds: string[];
      permission: WorkshopPermissions;
      action: 'grant' | 'revoke';
      frontendEnvironment?: string;
    };
  }) {
    try {
      // OASIS-4785: remove once beta is setup
      params.data.frontendEnvironment = Env.store.releaseChannel;
      const data = WorkshopsSchemas.updateWorkshopPermissions.parse(
        await OasisHttp.patch(`v1/workshops/${params.workshopId}/permissions`, {
          body: JSON.stringify(params.data),
        }).json()
      );

      Segment.track('Workshop Permissions Updated', params);

      return Ok({
        VIEW: data.VIEW || [],
        EDIT: data.EDIT || [],
        MANAGE: data.MANAGE || [],
      });
    } catch (error) {
      return HttpUtils.handleError(error, '[Workshops.updateUserPermissions]');
    }
  },

  /**
   * @name getWorkshopSettings
   * Get a workshops settings.
   */
  async getWorkshopSettings(workshopId: string) {
    try {
      const data = WorkshopsSchemas.getWorkshopSettings.parse(
        await OasisHttp.get(`v1/workshops/${workshopId}/sessions/settings`).json()
      );

      return Ok(data);
    } catch (error) {
      if (error instanceof HTTPError) {
        // If we dont have a session, create an initial version of one.
        if (error.response.status === 404) {
          return Workshops.initWorkshopSettings(workshopId);
        }
      }

      return HttpUtils.handleError(error, '[Workshops.getWorkshopSession]');
    }
  },

  /**
   * @name initWorkshopSettings
   * Initialize workshop settings object.
   */
  async initWorkshopSettings(workshopId: string) {
    try {
      const body: Record<string, { value: unknown }> = {
        version: { value: 1 },
      };

      await OasisHttp.put(`v1/workshops/${workshopId}/sessions/settings`, {
        body: JSON.stringify(body),
      }).json();

      return Ok(body);
    } catch (error) {
      return HttpUtils.handleError(error, '[Workshops.initWorkshopSettings]');
    }
  },

  /**
   * @name updateWorkshopSettings
   * Update a workshops settings.
   */
  async updateWorkshopSettings(params: {
    workshopId: string;
    currentSettingsVersion: number;
    attrs?: Record<string, { value: unknown }>;
  }) {
    try {
      const settings = await Workshops.getWorkshopSettings(params.workshopId);

      if (!settings.ok) {
        return Err({ code: 'BAD_REQUEST', message: 'Failed to get existing workshop settings' });
      }

      const body: Record<string, { value: unknown }> = {
        ...settings.value,
        ...params.attrs,
        version: {
          value: params.currentSettingsVersion + 1,
        },
      };

      await OasisHttp.put(`v1/workshops/${params.workshopId}/sessions/settings`, {
        body: JSON.stringify(body),
      }).json();

      return Ok(body);
    } catch (error) {
      return HttpUtils.handleError(error, '[Workshops.updateWorkshopSettings]');
    }
  },

  /**
   * @name getWorkshopThumbnail
   * Get a workshops thumbnail.
   */
  async getWorkshopThumbnail(workshopId: string, opts?: Options) {
    try {
      const data = WorkshopsSchemas.getWorkshopThumbnail.parse(
        await OasisHttp.post(`v1/workshops/${workshopId}/thumbnail`, opts).json()
      );

      const response = await fetch(data.signedUrl);

      if (response.status === 404) {
        return Err({ code: 'NOT_FOUND' });
      }

      const blob = await response.blob();

      return Ok(URL.createObjectURL(blob));
    } catch (error) {
      return HttpUtils.handleError(error, '[Workshops.getWorkshopThumbnail]');
    }
  },

  /**
   * @name getCurrentUsersPermission
   * Get a workshops thumbnail.
   */
  async getCurrentUsersPermission(workshopId: string) {
    try {
      const workshop = await Workshops.findWorkshopById(workshopId);

      if (!workshop.ok) {
        return workshop;
      }

      if (workshop.value.permission) {
        return Ok(workshop.value.permission);
      }

      const members = await Workshops.listWorkshopMembers(workshopId);

      if (!members.ok) {
        return members;
      }

      const admin = members.value.results.admins.find(admin => admin.autodeskId === Session.store.user?.id);

      if (admin) {
        return Ok('MANAGE' as const);
      }

      const member = members.value.results.members.find(member => member.autodeskId === Session.store.user?.id);

      if (member) {
        return Ok(member.permission);
      }

      return Err({ code: 'NOT_FOUND' });
    } catch (error) {
      return HttpUtils.handleError(error, '[Workshops.getCurrentUsersPermission]');
    }
  },

  async getActiveModelVersionId(workshopId: string) {
    const workshop = await Oasis.Workshops.getWorkshopSettings(workshopId);

    if (!workshop.ok) {
      return null;
    }

    // The murn - `{projectId}_{modelId}` is part of the new murnxfrm sequence - `M:urn;T:x,y,z;R:x,y,z,w;S:x,y,z`;
    const murnxfrmValue = workshop.value.murnxfrm?.value;
    // Parse ProjectId and VersionId from murnxfrmValue
    let [, versionId] =
      typeof murnxfrmValue === 'string' ? murnxfrmValue.replace('M:', '').split(/_(.*?)(;|$)/) : ['', ''];

    /**
     * TODO: Supporting both murnxfrm and the new distinct values. Eventually we can remove
     * all of this complete logic to extract values from the delimited string.
     */
    if (typeof workshop.value.murn?.value === 'string') {
      versionId = workshop.value.murn.value;
    }

    return versionId;
  },

  async getActiveModel(params: { projectId: string; workshopId: string }) {
    const workshop = await Oasis.Workshops.getWorkshopSettings(params.workshopId);

    if (!workshop.ok) {
      return Ok(null);
    }

    // The murn - `{projectId}_{modelId}` is part of the new murnxfrm sequence - `M:urn;T:x,y,z;R:x,y,z,w;S:x,y,z`;
    const murnxfrmValue = workshop.value.murnxfrm?.value;
    // Parse ProjectId and VersionId from murnxfrmValue
    let [, versionId] =
      typeof murnxfrmValue === 'string' ? murnxfrmValue.replace('M:', '').split(/_(.*?)(;|$)/) : ['', ''];

    /**
     * TODO: Supporting both murnxfrm and the new distinct values. Eventually we can remove
     * all of this complete logic to extract values from the delimited string.
     */
    if (typeof workshop.value.murn?.value === 'string') {
      versionId = workshop.value.murn.value;
    }

    if (!versionId) {
      return Ok(null);
    }

    const documentVersion = await Oasis.Files.findDocumentVersionById({
      projectId: params.projectId,
      documentVersionId: versionId,
    });

    if (!documentVersion.ok) {
      return Ok(null);
    }

    const activeViewGuid = workshop.value.mvid?.value as string | undefined;
    const activeView = await Oasis.Files.find3dView({
      projectId: params.projectId,
      documentVersionId: versionId,
      viewGuid: activeViewGuid,
    });

    if (!activeView.ok) {
      return Err({
        code: 'ACTIVE_VIEW_NOT_FOUND',
        description: 'Unable to find active 3d view. Has your model changed?',
      });
    }

    // Only persist the active folder urn if it's not already set.
    // We don't want to override if someone has navigated around but then someone else opens a model.
    if (
      !Oasis.Storage.getTemporary('activeFolderUrn') &&
      documentVersion.value.data.attributes.extension.data.originalItemUrn
    ) {
      const document = await Oasis.Files.findDocumentById({
        projectId: params.projectId,
        documentId: documentVersion.value.data.attributes.extension.data.originalItemUrn,
      });

      if (document.ok) {
        Oasis.Storage.setTemporary('activeFolderUrn', document.value.folderId);
      }
    }

    return Ok({
      versionId,
      model: documentVersion.value,
      activeViewGuid,
      activeView: activeView.ok ? activeView.value : null,
    });
  },
};

import { XIcon } from '@adsk/alloy-react-icon';

import { Link } from 'react-router-dom';
import type { Queries } from '~/shared/hooks/queries';
import { useTemporaryBranding } from '~/shared/hooks/use-temporary-branding';

interface Props {
  projectId: string;
  showLeaveButton?: boolean;
  workshop: ReturnType<typeof Queries.Workshops.useFindWorkshopById>;
}

export function WorkshopViewerHeader({ workshop, ...props }: Props) {
  const branding = useTemporaryBranding();

  return (
    <header className="flex columns-3 items-center h-12 px-2 bg-white border-b border-charcoal-200">
      <div className="w-48">
        <img src={branding.productIconUrl} alt={branding.productName + ' logo'} className="block h-[2.125rem]" />
      </div>

      <div className="flex-1 text-center">
        <p>{workshop.data?.name}</p>
      </div>

      <div className="w-48 flex justify-end">
        {/* @ts-ignore */}
        <Link to={`/projects/${props.projectId}`}>
          <XIcon />
        </Link>
      </div>
    </header>
  );
}
